exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-datenschutz-page-js": () => import("./../../../src/templates/datenschutz-page.js" /* webpackChunkName: "component---src-templates-datenschutz-page-js" */),
  "component---src-templates-impressum-page-js": () => import("./../../../src/templates/impressum-page.js" /* webpackChunkName: "component---src-templates-impressum-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-kontakt-page-js": () => import("./../../../src/templates/kontakt-page.js" /* webpackChunkName: "component---src-templates-kontakt-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

